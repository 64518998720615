import Api from "@/api/Api";

export default {
  signup(data) {
    return Api().post("/signup", data);
  },

  login(data) {
    return Api().post("/login", data);
  },

  logout() {
    return Api().post("/logout");
  },

  forgotPassword(data) {
    return Api().post("/forgot-password", data);
  },

  resetPassword(data) {
    return Api().post("/reset-password", data);
  },

  verifyEmail(data) {
    return Api().post("/verify-email", data);
  },

  auth() {
    return Api().get("/user");
  }
};
