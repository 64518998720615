import Api from "@/api/Api";

export default {
  getWorkflows(accountId, page, pageSize, sortBy, sortDesc, query, archiveView, linkedinId) {
    var params = {};
    if (query) params['query'] = query;
    if (archiveView) params['archive'] = archiveView;
    if (linkedinId) params['linkedin_id'] = linkedinId;
    if (page) params['page'] = page;
    if (pageSize) params['paginate'] = pageSize;
    if (sortBy && sortBy.length) params['sort_by'] = sortBy[0];
    if (sortDesc && sortDesc.length) params['sort_order'] = ((sortDesc[0]) ? 'desc' : 'asc');
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get('/accounts/' + accountId + '/workflows' + params);
  },

  searchWorkflows(accountId, query) {
    var params = {};
    if (query) params['query'] = query;
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get("/accounts/" + accountId + '/workflows' + params);
  },

  addWorkflow(accountId, data) {
    return Api().post("/accounts/" + accountId + '/workflows', data);
  },

  copyWorkflow(accountId, data) {
    return Api().post("/accounts/" + accountId + '/workflows/copy', data);
  },

  getWorkflow(workflowId) {
    return Api().get('/workflows/' + workflowId);
  },

  updateWorkflow(workflowId, data) {
    return Api().patch('/workflows/' + workflowId, data);
  },

  removeWorkflow(workflowId) {
    return Api().delete('/workflows/' + workflowId);
  },

  getContactsInWorkflow(workflowId, actionId, page, pageSize, sortBy, sortDesc, query, filter) {
    var params = {};
    if (actionId) params['action_id'] = actionId;
    if (page) params['page'] = page;
    if (pageSize) params['paginate'] = pageSize;
    if (sortBy && sortBy.length) params['sort_by'] = sortBy[0];
    if (sortDesc && sortDesc.length) params['sort_order'] = ((sortDesc[0]) ? 'desc' : 'asc');
    if (query) params['query'] = query;
    if (filter) params['filter'] = filter;
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get('/workflows/' + workflowId + '/contacts' + params);
  },

  addContactToWorkflow(workflowId, data) {
    return Api().post('/workflows/' + workflowId + '/contacts', data);
  },

  addProfilesToWorkflow(workflowId, data) {
    return Api().post('/workflows/' + workflowId + '/profiles', data);
  },

  updateWorkflowContact(workflowId, workflowContactId, data) {
    return Api().patch('/workflows/' + workflowId + '/contacts/' + workflowContactId, data);
  },

  updateWorkflowContacts(workflowId, data) {
    return Api().post('/workflows/' + workflowId + '/contacts/update', data);
  },

  removeContactFromWorkflow(workflowId, workflowContactId) {
    return Api().delete('/workflows/' + workflowId + '/contacts/' + workflowContactId);
  },

  removeContactsFromWorkflow(workflowId, data) {
    return Api().post('/workflows/' + workflowId + '/contacts/remove', data);
  },

  exportContactsInWorkflow(workflowId, sortBy, sortDesc, query, filter, contactIds) {
    var params = {};
    if (query) params['query'] = query;
    if (filter) params['filter'] = filter;
    if (contactIds) params['contact_ids'] = contactIds.join(',');
    if (sortBy && sortBy.length) params['sort_by'] = sortBy[0];
    if (sortDesc && sortDesc.length) params['sort_order'] = ((sortDesc[0]) ? 'desc' : 'asc');
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get("/workflows/" + workflowId + "/contacts/export/csv" + params);
  },

  addContactListToWorkflow(workflowId, data) {
    return Api().post('/workflows/' + workflowId + '/contact-lists', data);
  },

  getWorkflowActions(workflowId) {
    return Api().get("/workflows/" + workflowId + '/actions');
  },

  addActionToWorkflow(workflowId, data) {
    return Api().post('/workflows/' + workflowId + '/actions', data);
  },

  loadActionsFromWorkflowTemplate(workflowId, workflowTemplateId, data) {
    return Api().post('/workflows/' + workflowId + '/actions/load-from-template?template_id=' + workflowTemplateId, data);
  },

  updateWorkflowAction(workflowId, actionId, data) {
    return Api().patch('/workflows/' + workflowId + '/actions/' + actionId, data);
  },

  removeActionFromWorkflow(workflowId, actionId) {
    return Api().delete('/workflows/' + workflowId + '/actions/' + actionId);
  },

  updateWorkflowConnection(workflowId, actionId, data) {
    return Api().patch('/workflows/' + workflowId + '/actions/' + actionId + '/connections', data);
  },

  getWorkflowEvent(workflowId, page, pageSize) {
    var params = {};
    if (page) params['page'] = page;
    if (pageSize) params['paginate'] = pageSize;
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get("/workflows/" + workflowId + '/events' + params);
  },

  getWorkflowEventTypes(workflowId) {
    return Api().get('/workflows/' + workflowId + '/events/types');
  },

  getWorkflowChartByDate(workflowId, type, result, start, end) {
    var params = {};
    if (result !== null) params['result'] = result;
    if (start) params['start'] = start;
    if (end) params['end'] = end;
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get('/workflows/' + workflowId + '/chart-by-date/' + type + params);
  },

  getWorkflowChartByType(workflowId, start, end) {
    var params = {};
    if (start) params['start'] = start;
    if (end) params['end'] = end;
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get('/workflows/' + workflowId + '/chart-by-type' + params);
  },

  getWorkflowTemplates(accountId, page, pageSize, sortBy, sortDesc, query) {
    var params = {};
    if (query) params['query'] = query;
    if (page) params['page'] = page;
    if (pageSize) params['paginate'] = pageSize;
    if (sortBy && sortBy.length) params['sort_by'] = sortBy[0];
    if (sortDesc && sortDesc.length) params['sort_order'] = ((sortDesc[0]) ? 'desc' : 'asc');
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get('/accounts/' + accountId + '/workflow-templates' + params);
  },

  searchWorkflowTemplates(accountId, query) {
    var params = {};
    if (query) params['query'] = query;
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get("/accounts/" + accountId + '/workflow-templates' + params);
  },

  addWorkflowTemplate(accountId, data) {
    return Api().post("/accounts/" + accountId + '/workflow-templates', data);
  },

  getWorkflowTemplate(workflowTemplateId) {
    return Api().get('/workflow-templates/' + workflowTemplateId);
  },

  updateWorkflowTemplate(workflowTemplateId, data) {
    return Api().patch('/workflow-templates/' + workflowTemplateId, data);
  },

  removeWorkflowTemplate(workflowTemplateId) {
    return Api().delete('/workflow-templates/' + workflowTemplateId);
  },

  getWorkflowTemplateActions(workflowTemplateId) {
    return Api().get("/workflow-templates/" + workflowTemplateId + '/actions');
  },

  addActionToWorkflowTemplate(workflowTemplateId, data) {
    return Api().post('/workflow-templates/' + workflowTemplateId + '/actions', data);
  },

  updateWorkflowTemplateAction(workflowTemplateId, actionId, data) {
    return Api().patch('/workflow-templates/' + workflowTemplateId + '/actions/' + actionId, data);
  },

  removeActionFromWorkflowTemplate(workflowTemplateId, actionId) {
    return Api().delete('/workflow-templates/' + workflowTemplateId + '/actions/' + actionId);
  },

  updateWorkflowTemplateConnection(workflowTemplateId, actionId, data) {
    return Api().patch('/workflow-templates/' + workflowTemplateId + '/actions/' + actionId + '/connections', data);
  },

  getWorkflowTemplateParameters(workflowTemplateId, page, pageSize, sortBy, sortDesc, type, query) {
    var params = {};
    if (type) params['type'] = type;
    if (query) params['query'] = query;
    if (page) params['page'] = page;
    if (pageSize) params['paginate'] = pageSize;
    if (sortBy && sortBy.length) params['sort_by'] = sortBy[0];
    if (sortDesc && sortDesc.length) params['sort_order'] = ((sortDesc[0]) ? 'desc' : 'asc');
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get("/workflow-templates/" + workflowTemplateId + '/parameters' + params);
  },

  searchWorkflowTemplateParameters(workflowTemplateId, type, query, applied) {
    var params = {};
    if (type) params['type'] = type;
    if (query) params['query'] = query;
    if (applied) params['applied'] = applied;
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get("/workflow-templates/" + workflowTemplateId + '/parameters' + params);
  },

  getWorkflowTemplateParameter(workflowTemplateId, parameterId) {
    return Api().get('/workflow-templates/' + workflowTemplateId + '/parameters/' + parameterId);
  },

  addParameterToWorkflowTemplate(workflowTemplateId, data) {
    return Api().post('/workflow-templates/' + workflowTemplateId + '/parameters', data);
  },

  updateWorkflowTemplateParameter(workflowTemplateId, parameterId, data) {
    return Api().patch('/workflow-templates/' + workflowTemplateId + '/parameters/' + parameterId, data);
  },

  removeParameterFromWorkflowTemplate(workflowTemplateId, parameterId) {
    return Api().delete('/workflow-templates/' + workflowTemplateId + '/parameters/' + parameterId);
  },
};
