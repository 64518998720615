<template>
  <v-app-bar
    :color="!$store.state.isDarkMode ? 'blue' : 'darken-3'"
    class="darken-3"
    dark
    elevation="0"
    app
    dense
  >
    <v-toolbar-title></v-toolbar-title>

    <v-avatar
      v-if="$store.getters.authenticated"
      size="30"
      class="blue--text mr-3 text--darken-3"
    >
      <v-img
        src="../../assets/logo-white-30.png"
        max-width="30"
        max-height="30"
        contain
      ></v-img>
    </v-avatar>

    <base-main-nav-btn
      v-if="$store.getters.authenticated"
      to="/"
      icon="mdi-view-dashboard"
    >Dashboard</base-main-nav-btn>

    <base-main-nav-btn
      v-if="account"
      :to="getAccountRoute('/workflows')"
      :disabled="!$store.getters.isActive"
    >
      <v-icon class="mr-1" small>mdi-cogs</v-icon>
      Workflows
    </base-main-nav-btn>

    <base-main-nav-btn
      v-if="account && !truncateMenuL2"
      :to="getAccountRoute('/linkedin-conversations')"
      icon="mdi-forum"
      :disabled="!$store.getters.isActive"
    >
      <v-icon class="mr-1" small>mdi-forum</v-icon>
      Conversations
    </base-main-nav-btn>

    <v-menu
      v-if="account"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-account-box-multiple"
          v-bind="attrs"
          v-on="on"
          :disabled="!$store.getters.isActive"
        >
          Contacts
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-account-box-multiple</v-icon>
          Contacts
          <base-info-icon
            label="Manage and organize your contact data."
          />
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          :to="getAccountRoute('/contacts')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-contacts</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/companies')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Companies</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-briefcase</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/contact-lists')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Lists</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-table-account</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/contacts-import')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Import</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-cloud-upload</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <base-main-nav-btn
      v-if="account && !truncateMenu"
      :to="getAccountRoute('/task-boards')"
      icon="mdi-bullseye-arrow"
      :disabled="!$store.getters.isActive"
    >Tasks</base-main-nav-btn>

    <base-main-nav-btn
      v-if="account && !truncateMenu"
      :to="getAccountRoute('/notes')"
      icon="mdi-note"
      :disabled="!$store.getters.isActive"
    >Notes</base-main-nav-btn>

    <base-main-nav-btn
      v-if="account && !truncateMenu"
      :to="getAccountRoute('/tags')"
      icon="mdi-tag"
      :disabled="!$store.getters.isActive"
    >Tags</base-main-nav-btn>

    <v-menu
      v-if="account"
      v-model="menuLinkedin"
      bottom
      left
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          v-bind="attrs"
          v-on="on"
          :disabled="!$store.getters.isActive"
        >
          <v-icon class="mr-1" small>mdi-linkedin</v-icon>
          LinkedIn
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-linkedin</v-icon>
          LinkedIn
          <base-info-icon
            label="Manage your LinkedIn accounts and the related data."
          />
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          :to="getAccountRoute('/linkedin')"
          @click="closeLinkedinMenu"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Accounts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-box-multiple</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          :to="getAccountRoute('/linkedin-conversations')"
          @click="closeLinkedinMenu"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Conversations</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-forum</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          :to="getAccountRoute('/linkedin-searches')"
          @click="closeLinkedinMenu"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Searches</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-search</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          :to="getAccountRoute('/linkedin-connections')"
          @click="closeLinkedinMenu"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Connections</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-multiple</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-group
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Invitations</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :to="getAccountRoute('/linkedin-invitations-received')"
            @click="closeLinkedinMenu"
            link
            class="pl-6"
            :disabled="!$store.getters.isActive"
          >
            <v-list-item-content>
              <v-list-item-title>Received</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-account-multiple-plus</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            :to="getAccountRoute('/linkedin-invitations-sent')"
            @click="closeLinkedinMenu"
            link
            class="pl-6"
            :disabled="!$store.getters.isActive"
          >
            <v-list-item-content>
              <v-list-item-title>Sent</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-account-multiple-plus-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-item
          :to="getAccountRoute('/linkedin-suggestions')"
          @click="closeLinkedinMenu"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Suggestions</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-question</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/linkedin-views')"
          @click="closeLinkedinMenu"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Views</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-target-account</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          :to="getAccountRoute('/linkedin-groups')"
          @click="closeLinkedinMenu"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Groups</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-group</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-group
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>LinkedIn Company Page</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :to="getAccountRoute('/linkedin-company-posts')"
            @click="closeLinkedinMenu"
            link
            class="pl-6"
            :disabled="!$store.getters.isActive"
          >
            <v-list-item-content>
              <v-list-item-title>Posts</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-note-text</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            :to="getAccountRoute('/linkedin-company-invitations-sent')"
            @click="closeLinkedinMenu"
            link
            class="pl-6"
            :disabled="!$store.getters.isActive"
          >
            <v-list-item-content>
              <v-list-item-title>Invitations Sent</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-briefcase-plus</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-menu>

    <v-menu
      v-if="account && !truncateMenu"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          v-bind="attrs"
          v-on="on"
          :disabled="!$store.getters.isActive"
        >
          <v-icon class="mr-1" small>mdi-wrench</v-icon>
          Integrations
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-wrench</v-icon>
          Integrations
          <base-info-icon
            v-if="$store.getters.isBasic"
            label="These features are available on Regular and Premium plans."
            to="/billing"
          />
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          :to="getAccountRoute('/integrations')"
          link
          :disabled="!$store.getters.isActive || $store.getters.isBasic"
        >
          <v-list-item-content>
            <v-list-item-title>API Integrations</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-api</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/webhooks')"
          link
          :disabled="!$store.getters.isActive || $store.getters.isBasic"
        >
          <v-list-item-content>
            <v-list-item-title>Webhooks</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-webhook</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="account && !truncateMenuL1"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-chart-box"
          v-bind="attrs"
          v-on="on"
          :disabled="!$store.getters.isActive"
        >
          <v-icon class="mr-1" small>mdi-chart-box</v-icon>
          Reports
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-chart-box</v-icon>
          Reports
          <base-info-icon
            label="Track and measure performance of your account."
          />
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          :to="getAccountRoute('/overview')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-calendar</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/reports')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Charts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-chart-areaspline</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="account && truncateMenu"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-table"
          v-bind="attrs"
          v-on="on"
          :disabled="!$store.getters.isActive"
        >
          <span v-if="!truncateMenuL3">More</span>
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-list-item
          :to="getAccountRoute('/task-boards')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Tasks</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-bullseye-arrow</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/notes')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Notes</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-note</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/tags')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Tags</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-tag</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>
          <v-icon small class="mr-1">mdi-chart-box</v-icon>
          Reports
          <base-info-icon
            label="Track and measure performance of your account."
          />
        </v-subheader>

        <v-list-item
          :to="getAccountRoute('/overview')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-calendar</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/reports')"
          link
          :disabled="!$store.getters.isActive"
        >
          <v-list-item-content>
            <v-list-item-title>Charts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-chart-areaspline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>
          <v-icon small class="mr-1">mdi-wrench</v-icon>
          Integrations
          <base-info-icon
            v-if="$store.getters.isBasic"
            label="These features are available on Regular and Premium plans."
            to="/billing"
          />
        </v-subheader>

        <v-list-item
          :to="getAccountRoute('/integrations')"
          link
          :disabled="!$store.getters.isActive || $store.getters.isBasic"
        >
          <v-list-item-content>
            <v-list-item-title>API Integrations</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-api</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          :to="getAccountRoute('/webhooks')"
          link
          :disabled="!$store.getters.isActive || $store.getters.isBasic"
        >
          <v-list-item-content>
            <v-list-item-title>Webhooks</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-webhook</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-spacer></v-spacer>

    <v-menu
        v-if="$route.name !== 'Global Search' && $store.getters.authenticated"
        :close-on-content-click="false"
        origin="top right"
        bottom
        class="search-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            class="mx-1"
            small
            v-on="on"
            v-bind="attrs"
        >
          <v-icon small>mdi-magnify</v-icon>
        </v-btn>
      </template>

      <v-card
          class="pa-2"
      >
        <base-view-header-search
            v-model="globalSearchQuery"
            label="Global Search"
            @keyup.enter="redirectToGlobalSearchResults"
        ></base-view-header-search>
        <base-sidebar-btn
            @click="redirectToGlobalSearchResults"
            label="Search"
            class="ml-1 primary"
        ></base-sidebar-btn>
      </v-card>
    </v-menu>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          href="https://docs.webwalker.io/wiki/"
          target="_blank"
          link
          icon
          class="mx-1"
          small
          v-on="on"
        >
          <v-icon small>mdi-book-open-outline</v-icon>
        </v-btn>
      </template>
      <span>Knowledge Base</span>
    </v-tooltip>

    <main-nav-notifications
      v-if="$store.getters.authenticated"
    ></main-nav-notifications>

    <v-btn
      v-if="$store.getters.authenticated"
      to="/settings"
      link
      icon
      class="mx-1"
      small
    >
      <v-icon small>mdi-account-circle</v-icon>
    </v-btn>

    <div
      v-if="$store.getters.authenticated"
    >
      <v-tooltip v-if="$darkMode" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', false)">
            <v-icon small color="yellow" class="mr-1">mdi-moon-waxing-crescent</v-icon>
          </v-btn>
        </template>
        <span>Disable dark mode</span>
      </v-tooltip>
      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', true)">
            <v-icon small color="yellow">mdi-white-balance-sunny</v-icon>
          </v-btn>
        </template>
        <span>Enable dark mode</span>
      </v-tooltip>
    </div>

    <v-divider
      v-if="$store.getters.authenticated"
      v-show="!this.isAuthView"
      vertical
      class="ml-2 mr-4"
    ></v-divider>

    <v-menu
      v-if="$store.getters.authenticated"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          class="main-nav-btn mx-1"
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-3" small>mdi-domain</v-icon>
          {{ ((account) ? account.name : 'Select Account') }}
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-subheader style="max-height: 30px;">{{ this.$store.state.user.name }} {{ this.$store.state.user.email }}</v-subheader>
        <v-subheader
          v-if="account"
        >{{ account.name }}</v-subheader>

        <v-divider></v-divider>

        <v-subheader>Account Management</v-subheader>

        <v-list-item
          :to="getAccountRoute('/account')"
          link
          dense
        >
          <v-list-item-content>
            <v-list-item-title>Configuration</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-cog</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          v-if="$store.getters.isOwner"
          :to="getAccountRoute('/users')"
          link
          dense
        >
          <v-list-item-content>
            <v-list-item-title>Manage Users</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-group</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          v-if="$store.getters.isOwner"
          :to="getAccountRoute('/billing')"
          link
          dense
        >
          <v-list-item-content>
            <v-list-item-title>Manage Billing</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-credit-card-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          to="/accounts"
          link
        >
          <v-list-item-content>
            <v-list-item-title>My Accounts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          v-for="(acc, index) in this.$store.state.accounts"
          :key="index"
          :disabled="acc.id == account.id"
          @click="switchAccountRoute(acc)"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ acc.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-location-enter</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider
      v-if="$store.getters.authenticated"
      v-show="!this.isAuthView"
      vertical
    ></v-divider>

    <v-btn
      class="main-nav-btn mx-2"
      small
      text v-if="!this.$store.getters.authenticated"
      :to="{name: 'Login'}"
    >
      <v-icon class="mr-1" small>mdi-login</v-icon>
      Login
    </v-btn>
    <v-btn
      class="main-nav-btn mx-2"
      small
      text v-if="!this.$store.getters.authenticated"
      :to="{name: 'Sign Up'}"
    >
      Sign Up
    </v-btn>
    <v-btn
      class="mx-2"
      icon
      small
      v-if="this.$store.getters.authenticated"
      @click="logoutConfirmation = true"
    >
      <v-icon small>mdi-logout</v-icon>
    </v-btn>
    <base-dialog-confirmation
      v-model="logoutConfirmation"
      prompt="Do you really want to logout?"
      confirm-text="Logout"
      confirm-color="red"
      @confirm="logout"
      @cancel="logoutConfirmation = false"
    ></base-dialog-confirmation>
  </v-app-bar>
</template>

<script>
import MainNavNotifications from '@/components/layout/MainNavNotifications.vue';

export default {
  name: 'MainNavV2',

  components: {
    MainNavNotifications,
  },

  data() {
    return {
      logoutConfirmation: false,
      menuLinkedin: false,
      truncateMenu: false,
      truncateMenuL1: false,
      truncateMenuL2: false,
      truncateMenuL3: false,
      globalSearchQuery: '',
    };
  },

  computed: {
    accountId: function () {
      var params = this.$router.history.current.params;
      if (params && params['accountId']) return params['accountId'];
      if (this.$store.state.account) return this.$store.state.account.id;
      if (this.$store.state.accounts) return this.$store.state.accounts[0];
      return null;
    },
    account: function () {
      if (!this.$store.state.accounts || !this.accountId) return false;
      return this.$store.state.accounts.find(x => x.id == this.accountId);
    },
    isAuthView() {
      // Check if on auth pages (login, sign up, password reset, etc)
      if (this.$route && this.$route.meta) {
        return this.$route.meta.public;
      }
      return false;
    },
  },

  created() {
    this.resizeScreen();
    window.addEventListener("resize", this.resizeScreen);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeScreen);
  },

  methods: {

    getAccountRoute: function (route) {
      if (!this.accountId) return route;
      return '/account/' + this.accountId + route;
    },

    switchAccountRoute: function(account) {
      // Get the current route and change the account id
      var params = this.$router.history.current.params;
      if (!params) params = {};
      params['accountId'] = account.id;

      // Store as the last selected account
      this.$store.commit('setAccount', account);

      // Reload the page with updated params
      this.$router.push({
        "name": this.$router.history.current.name,
        "params": params,
      }).then(() => {
        window.location.reload();
      }).catch(()=>{
        window.location.reload();
      });
    },

    logout: function () {
      this.$emit('logout');
    },

    resizeScreen: function () {
      this.truncateMenu = window.innerWidth < 1600;
      this.truncateMenuL1 = window.innerWidth < 1300;
      this.truncateMenuL2 = window.innerWidth < 1200;
      this.truncateMenuL3 = window.innerWidth < 1050;
    },

    redirectToGlobalSearchResults: function () {
      this.$router.push({
        'name': 'Global Search',
        "params": {
          'query': this.globalSearchQuery,
        },
      })
    },

    closeLinkedinMenu: function () {
      this.menuLinkedin = false;
    },
  },
};
</script>
