let data = {
  allConditionTypes: [
    {
      'name': 'known',
      'label': 'is known',
      'value': null,
    },
    {
      'name': 'unknown',
      'label': 'is unknown',
      'value': null,
    },
    {
      'name': 'contains',
      'label': 'contains',
      'value': 'text',
    },
    {
      'name': 'not_contains',
      'label': "doesn't contain",
      'value': 'text',
    },
  ],
  conditionTypesByPropertyType: {
    'boolean': [
      'known',
      'unknown',
    ],
    'text': [
      'contains',
      'not_contains',
    ],
  },
  conditionByName: function (name) {
    return data.allConditionTypes.find(x => x.name === name);
  },
  conditionsByType: function (type) {
    var types = data.conditionTypesByPropertyType[type];
    types = data.allConditionTypes.filter(function (type) {
        return types.includes(type.name);
    });
    return types;
  },
};

let ContactCompanyFilters = function() {
  return data;
};

export default ContactCompanyFilters;
