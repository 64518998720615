let data = {
  allConditionTypes: [
    {
      'name': 'enrolled',
      'label': 'enrolled',
      'value': null,
    },
    {
      'name': 'not_enrolled',
      'label': 'not enrolled',
      'value': null,
    },
    {
      'name': 'linkedin_view_profile',
      'label': 'viewed LinkedIn profile',
      'value': null,
    },
    {
      'name': 'linkedin_follow_profile',
      'label': 'followed LinkedIn profile',
      'value': null,
    },
    {
      'name': 'linkedin_unfollow_profile',
      'label': 'unfollowed LinkedIn profile',
      'value': null,
    },
    {
      'name': 'linkedin_endorse_profile',
      'label': 'endorsed LinkedIn profile',
      'value': null,
    },
    {
      'name': 'linkedin_like_post',
      'label': 'liked a post by LinkedIn profile',
      'value': null,
    },
    {
      'name': 'linkedin_message_profile',
      'label': 'messaged LinkedIn profile',
      'value': null,
    },
    {
      'name': 'linkedin_connect_profile',
      'label': 'invited LinkedIn profile to connect',
      'value': null,
    },
    {
      'name': 'linkedin_withdraw_invitation',
      'label': 'withdrawn LinkedIn invitation to connect',
      'value': null,
    },
    {
      'name': 'linkedin_invite_to_follow_company',
      'label': 'invited LinkedIn profile to follow company',
      'value': null,
    },
    {
      'name': 'linkedin_withdraw_invite_to_follow_company',
      'label': 'withdrawn LinkedIn invitation to follow company',
      'value': null,
    },
  ],
  conditionByName: function (name) {
    return data.allConditionTypes.find(x => x.name === name);
  },
  conditions: function () {
    return data.allConditionTypes;
  },
};

let ContactWorkflowFilters = function() {
  return data;
};

export default ContactWorkflowFilters;
