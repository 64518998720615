let data = {
  allConditionTypes: [
    {
      'name': 'assigned',
      'label': 'assigned',
      'value': null,
    },
    {
      'name': 'not_assigned',
      'label': 'not assigned',
      'value': null,
    },
  ],
  conditionByName: function (name) {
    return data.allConditionTypes.find(x => x.name === name);
  },
  conditions: function () {
    return data.allConditionTypes;
  },
};

let ContactUserFilters = function() {
  return data;
};

export default ContactUserFilters;
