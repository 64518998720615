import WorkflowsApi from "@/api/WorkflowsApi";
import ContactFilters from "@/helpers/ContactFilters";
import _ from 'lodash';

export default {
  actionTypes: {
    'new': {
      title: 'New',
      description: 'Edit to set type & details.',
      icon: 'mdi-hammer-wrench',
      slots: 0,
    },
    'delay': {
      title: 'Delay',
      description: 'Wait specified <b>amount</b> of time.',
      icon: 'mdi-clock-outline',
      slots: 1,
      delay: true,
      group: 'General',
    },
    'if': {
      title: 'Condition',
      description: 'Check specific condition.',
      icon: 'mdi-help-rhombus',
      slots: 2,
      contactFilter: true,
      timeout: true,
      group: 'General',
    },
    'random': {
      title: 'Random',
      description: 'Select the next step randomly.',
      icon: 'mdi-dice-multiple',
      spread: true,
      group: 'General',
    },
    'alert_send': {
      title: 'Send Alert',
      description: 'Send alert (push\\email).',
      icon: 'mdi-bell-alert',
      slots: 1,
      details: {
        'message': '',
      },
      limit: true,
      group: 'General',
    },
    'list_add': {
      title: 'Add to List',
      description: 'Add to the contact list.',
      icon: 'mdi-account-plus',
      slots: 1,
      contactList: true,
      limit: true,
      group: 'Lists',
    },
    'list_remove': {
      title: 'Remove from List',
      description: 'Remove from the contact list.',
      icon: 'mdi-account-minus',
      slots: 1,
      contactList: true,
      limit: true,
      group: 'Lists',
    },
    'tag_add': {
      title: 'Add Tag',
      description: 'Add tag to the contact.',
      icon: 'mdi-tag-plus',
      slots: 1,
      tag: true,
      limit: true,
      group: 'Tags',
    },
    'tag_remove': {
      title: 'Remove Tag',
      description: 'Remove tag from the contact.',
      icon: 'mdi-tag-minus',
      slots: 1,
      tag: true,
      limit: true,
      group: 'Tags',
    },
    'note_add': {
      title: 'Add Note',
      description: 'Add note to the contact.',
      icon: 'mdi-note-plus',
      slots: 1,
      note: true,
      limit: true,
      group: 'Notes',
    },
    'note_remove': {
      title: 'Remove Note',
      description: 'Remove note from the contact.',
      icon: 'mdi-note-minus',
      slots: 1,
      note: true,
      limit: true,
      group: 'Notes',
    },
    'task_add': {
      title: 'Add Task',
      description: 'Add to the task.',
      icon: 'mdi-note-plus',
      slots: 1,
      task: true,
      limit: true,
      group: 'Tasks',
    },
    'task_remove': {
      title: 'Remove Task',
      description: 'Remove from the task.',
      icon: 'mdi-note-minus',
      slots: 1,
      task: true,
      limit: true,
      group: 'Tasks',
    },
    'workflow_enroll': {
      title: 'Enroll into Workflow',
      description: 'Enroll into the workflow.',
      icon: 'mdi-account-cog',
      slots: 1,
      workflow: true,
      limit: true,
      spread: true,
      group: 'Workflows',
    },
    'workflow_pause': {
      title: 'Pause in Workflow',
      description: 'Pause contact in the workflow.',
      icon: 'mdi-cog-pause',
      slots: 1,
      workflow: true,
      limit: true,
      group: 'Workflows',
    },
    'workflow_resume': {
      title: 'Resume in Workflow',
      description: 'Resume contact in the workflow.',
      icon: 'mdi-cog-play',
      slots: 1,
      workflow: true,
      limit: true,
      group: 'Workflows',
    },
    'linkedin_find_profile': {
      title: 'Find Profile',
      description: 'Find LinkedIn profile page.',
      icon: 'mdi-account-search',
      slots: 1,
      linkedin: true,
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_view_profile': {
      title: 'View Profile',
      description: 'Visit LinkedIn profile page.',
      icon: 'mdi-account-search',
      slots: 1,
      linkedin: true,
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_follow_profile': {
      title: 'Follow Profile',
      description: 'Follow LinkedIn profile.',
      icon: 'mdi-account-eye',
      slots: 1,
      linkedin: true,
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_unfollow_profile': {
      title: 'Unfollow Profile',
      description: 'Unfollow LinkedIn profile.',
      icon: 'mdi-account-eye-outline',
      slots: 1,
      linkedin: true,
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_endorse_profile': {
      title: 'Endorse Profile',
      description: 'Endorse LinkedIn profile.',
      icon: 'mdi-account-heart',
      slots: 1,
      linkedin: true,
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_like_post': {
      title: 'Like Post',
      description: 'Like a recent post by LinkedIn profile.',
      icon: 'mdi-thumb-up',
      slots: 1,
      linkedin: true,
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_message_profile': {
      title: 'Message Profile',
      description: 'Send a message to LinkedIn profile.',
      icon: 'mdi-message',
      slots: 1,
      linkedin: true,
      details: {
        'message': '',
        'allow_inmail': false,
      },
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_connect_profile': {
      title: 'Connect Profile',
      description: 'Invite LinkedIn profile to connect.',
      icon: 'mdi-account-multiple',
      slots: 1,
      linkedin: true,
      details: {
        'message': '',
      },
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_withdraw_invitation': {
      title: 'Withdraw Invitation',
      description: 'Withdraw invitation to connect on LinkedIn.',
      icon: 'mdi-account-cancel-outline',
      slots: 1,
      linkedin: true,
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_invite_to_follow_company': {
      title: 'Invite to Follow Company ',
      description: 'Invite LinkedIn profile to follow company page on LinkedIn.',
      icon: 'mdi-account-multiple',
      slots: 1,
      linkedin: true,
      details: {
        'company_id': '',
      },
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
    'linkedin_withdraw_invite_to_follow_company': {
      title: 'Withdraw Invite to Follow Company',
      description: 'Withdraw invitation to follow company on LinkedIn.',
      icon: 'mdi-account-cancel-outline',
      slots: 1,
      linkedin: true,
      details: {
        'company_id': '',
      },
      limit: true,
      spread: true,
      group: 'LinkedIn',
    },
  },

  getTypeTitle (type) {
    // Get Node title from Workflow Action type
    return (this.actionTypes[type]) ? this.actionTypes[type]['title'] : type;
  },

  getTypeDescription (type, data, workflow) {
    if (!data) data = {};
    if (!workflow) workflow = {};
    // Get Node description from Workflow Action type
    if (type == 'delay') {
      if (data.delay) {
        return 'Wait <b>' + data.delay + '</b> hours.';
      }
    } else if (type == 'if') {
      if (data.contact_filter) {
        return 'If ' + ContactFilters().getSimpleDescription(workflow.account_id, workflow.id, data.contact_filter, true) + '.';
      }
    } else if (type == 'list_add') {
      if (data.contact_list) {
        return 'Add to the <b>' + data.contact_list.name + '</b> contact list.';
      }
    } else if (type == 'list_remove') {
      if (data.contact_list) {
        return 'Remove from the <b>' + data.contact_list.name + '</b> contact list.';
      }
    } else if (type == 'tag_add') {
      if (data.tag) {
        return 'Add <b>' + data.tag.label + '</b> tag to the contact.';
      }
    } else if (type == 'tag_remove') {
      if (data.tag) {
        return 'Remove <b>' + data.tag.label + '</b> tag from the contact.';
      }
    } else if (type == 'note_add') {
      if (data.note) {
        return 'Add <b>' + data.note.label + '</b> note to the contact.';
      }
    } else if (type == 'note_remove') {
      if (data.note) {
        return 'Remove <b>' + data.note.label + '</b> note from the contact.';
      }
    } else if (type == 'task_add') {
      if (data.task) {
        return 'Add to the <b>' + data.task.label + '</b> task.';
      }
    } else if (type == 'task_remove') {
      if (data.task) {
        return 'Remove from the <b>' + data.task.label + '</b> task.';
      }
    } else if (type == 'workflow_enroll') {
      if (data.workflow) {
        return 'Enroll into the <b>' + data.workflow.name + '</b> workflow.';
      }
    } else if (type == 'workflow_pause') {
      if (data.workflow) {
        return 'Pause contact in the <b>' + data.workflow.name + '</b> workflow.';
      }
    } else if (type == 'workflow_resume') {
      if (data.workflow) {
        return 'Resume contact in the <b>' + data.workflow.name + '</b> workflow.';
      }
    }
    return (this.actionTypes[type]) ? this.actionTypes[type]['description'] : '';
  },

  getTypeSlots (type) {
    // Get Node max slots from Workflow Action type
    return (this.actionTypes[type]) ? this.actionTypes[type]['slots'] : undefined;
  },

  getTypeDetails (type) {
    // Get Node details from Workflow Action type
    return (this.actionTypes[type]) ? { ...this.actionTypes[type]['details'] } : undefined;
  },

  getTypeIcon (type) {
    // Get Node icon from Workflow Action type
    return (this.actionTypes[type]) ? this.actionTypes[type]['icon'] : undefined;
  },

  getEventColor(event) {
    if (event.type == 'start') {
      return 'blue';
    } else if (event.type == 'finish') {
      return 'blue';
    } else if (event.result) {
      return 'green';
    } else if (event.result === false) {
      return 'red';
    }
    return 'grey';
  },

  getEventDescription(event) {
    var description = '';

    if (event.result) {
      description = '<strong>Successfully</strong> ';
    } else if (event.result === false) {
      description = '<strong>Unsuccessfully</strong> ';
    }

    if (event.type == 'start') {
      description += 'started workflow.';
    } else if (event.type == 'finish') {
      description += 'finished workflow.';
    } else if (event.type == 'delay') {
      description += 'waited specified amount of time.';
    } else if (event.type == 'random') {
      description += 'selected random next step.';
    } else if (event.type == 'if') {
      // description += 'checked specific condition.';
      description = event.result ? 'Passed condition check.' : 'Did not pass condition check.';
    } else if (event.type == 'alert_send') {
      description += 'sent alert.';
    } else if (event.type == 'list_add') {
      description += 'added to contact list.';
    } else if (event.type == 'list_remove') {
      description += 'removed from contact list.';
    } else if (event.type == 'tag_add') {
      description += 'added tag.';
    } else if (event.type == 'tag_remove') {
      description += 'removed tag.';
    } else if (event.type == 'note_add') {
      description += 'added note.';
    } else if (event.type == 'note_remove') {
      description += 'removed note.';
    } else if (event.type == 'task_add') {
      description += 'added to task.';
    } else if (event.type == 'task_remove') {
      description += 'removed from task.';
    } else if (event.type == 'workflow_enroll') {
      description += 'enrolled into workflow.';
    } else if (event.type == 'workflow_pause') {
      description += 'paused in workflow.';
    } else if (event.type == 'workflow_resume') {
      description += 'resumed in workflow.';
    } else if (event.type == 'linkedin_find_profile') {
      description += 'found on LinkedIn.';
    } else if (event.type == 'linkedin_view_profile') {
      description += 'visited on LinkedIn.';
    } else if (event.type == 'linkedin_follow_profile') {
      description += 'followed on LinkedIn.';
    } else if (event.type == 'linkedin_unfollow_profile') {
      description += 'unfollowed on LinkedIn.';
    } else if (event.type == 'linkedin_endorse_profile') {
      description += 'endorsed on LinkedIn.';
    } else if (event.type == 'linkedin_like_post') {
      description += 'liked their post on LinkedIn.';
    } else if (event.type == 'linkedin_message_profile') {
      description += 'sent a message via LinkedIn.';
    } else if (event.type == 'linkedin_connect_profile') {
      description += 'invited on LinkedIn to connect.';
    } else if (event.type == 'linkedin_withdraw_invitation') {
      description += 'withdrawn invitation to connect on LinkedIn.';
    } else if (event.type == 'linkedin_invite_to_follow_company') {
      description += 'invited on LinkedIn to follow company page.';
    } else if (event.type == 'linkedin_withdraw_invite_to_follow_company') {
      description += 'withdrawn invitation to follow company on LinkedIn.';
    } else {
      description += event.type;
    }

    return description;
  },

  addAction(data, workflowId, actionChanges, callback) {
    // Add a new Workflow Action
    var addData = { ...data };
    delete addData['id'];
    WorkflowsApi.addActionToWorkflow(workflowId, addData).then((response) => {
      var action = response.data.data;
      if (action && action.id) {
        [
          'add',
          'update',
          'remove',
          'disconnect',
          'connect',
        ].forEach((type) => {
          if (actionChanges[type]) {
            actionChanges[type].forEach((change) => {
              if (data['id'] == change['id']) {
                change['id'] = action.id;
              }
              if (data['id'] == change['parent']) {
                change['parent'] = action.id;
              }
            });
          }
        });
      }
      this.processActionChanges(workflowId, actionChanges, callback);
    }).catch(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    });
  },

  updateAction(data, workflowId, actionChanges, callback) {
    // Update Workflow Action
    WorkflowsApi.updateWorkflowAction(workflowId, data.id, data).then(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    }).catch(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    });
  },

  removeAction(data, workflowId, actionChanges, callback) {
    // Remove Workflow Action
    WorkflowsApi.removeActionFromWorkflow(workflowId, data.id).then(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    }).catch(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    });
  },

  disconnectAction(data, workflowId, actionChanges, callback) {
    // Disconnect Workflow Action from another Workflow Action
    WorkflowsApi.updateWorkflowConnection(workflowId, data.parent, {
      "id": data.id,
      "slot": data.slot,
      "type": 'remove',
    }).then(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    }).catch(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    });
  },

  connectAction(data, workflowId, actionChanges, callback) {
    // Connect Workflow Action to some other Workflow Action
    WorkflowsApi.updateWorkflowConnection(workflowId, data.parent, {
      "id": data.id,
      "slot": data.slot,
      "type": 'add',
    }).then(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    }).catch(() => {
      this.processActionChanges(workflowId, actionChanges, callback);
    });
  },

  processActionChanges(workflowId, actionChanges, callback) {
    // Process the Workflow Actions changes chain
    // It will add\update\delete Workflow Actions and Connections
    // one by one, to reflect any user-made changes in the workflow.

    if (!actionChanges || !('add' in actionChanges)) {
      if (callback) callback();
      return false;
    }

    var action = null;
    if (actionChanges['add'].length) {
      action = actionChanges['add'][0];
      actionChanges['add'].shift();
      this.addAction(action, workflowId, actionChanges, callback);
      return true;
    } else if (actionChanges['update'].length) {
      action = actionChanges['update'][0];
      actionChanges['update'].shift();
      this.updateAction(action, workflowId, actionChanges, callback);
      return true;
    } else if (actionChanges['disconnect'].length) {
      action = actionChanges['disconnect'][0];
      actionChanges['disconnect'].shift();
      this.disconnectAction(action, workflowId, actionChanges, callback);
      return true;
    } else if (actionChanges['remove'].length) {
      action = actionChanges['remove'][0];
      actionChanges['remove'].shift();
      this.removeAction(action, workflowId, actionChanges, callback);
      return true;
    } else if (actionChanges['connect'].length) {
      action = actionChanges['connect'][0];
      actionChanges['connect'].shift();
      this.connectAction(action, workflowId, actionChanges, callback);
      return true;
    } else {
      if (callback) callback();
      return false;
    }
  },

  getLinearActionChanges(actions, workflowActions, workflowConnections) {
    // List of changes compared with the original workflow
    var changes = {
      'add': [],
      'update': [],
      'remove': [],
      'disconnect': [],
      'connect': [],
    };

    // Check for deleted & detached Workflow Actions
    workflowActions.forEach((workflowAction) => {
      var action = _.find(actions, { "id": workflowAction.id });
      if (action) return; // not removed

      // Workflow Action removed

      changes['remove'].push({
        'id': workflowAction.id,
      });

      var connection = _.find(workflowConnections, { "target_workflow_action_id": workflowAction.id });
      if (connection) {
        changes['disconnect'].push({
          'id': workflowAction.id,
          'parent': connection.source_workflow_action_id,
          'slot': connection.slot,
        });
      }
    });

    // Check for new and updated Workflow Actions
    var parent = false;
    actions.forEach((action) => {
      if ((action.type == 'start') ||
          (action.type == 'end') ||
          (action.type == 'new')) return;

      var data = {
        'id': action.id,
        'type': action.type,
        'spread': action.spread,
        'linkedin_id': null,
        'contact_list_id': null,
        'tag_id': null,
        'note_id': null,
        'task_id': null,
        'action_workflow_id': null,
        'contact_filter': null,
      };

      if (action.linkedin) data['linkedin_id'] = action.linkedin.id;
      if (action.contact_list) data['contact_list_id'] = action.contact_list.id;
      if (action.tag) data['tag_id'] = action.tag.id;
      if (action.note) data['note_id'] = action.note.id;
      if (action.task) data['task_id'] = action.task.id;
      if (action.workflow) data['action_workflow_id'] = action.workflow.id;
      if (action.contact_filter) data['contact_filter'] = action.contact_filter;
      if (action.details) data['details'] = { ...action.details };
      if (action.delay) data['delay'] = action.delay;
      if (action.timeout) data['timeout'] = action.timeout;
      if (action.limit) data['limit'] = action.limit;

      var workflowAction = _.find(workflowActions, { "id": action.id });
      if (workflowAction) {

        // Existing Workflow Action

        var changed = false;
        if (workflowAction.type != data.type) {
          changed = true;
        }
        if (workflowAction.linkedin_id != data.linkedin_id) {
          changed = true;
        }
        if (workflowAction.contact_list_id != data.contact_list_id) {
          changed = true;
        }
        if (workflowAction.tag_id != data.tag_id) {
          changed = true;
        }
        if (workflowAction.note_id != data.note_id) {
          changed = true;
        }
        if (workflowAction.task_id != data.task_id) {
          changed = true;
        }
        if (workflowAction.action_workflow_id != data.action_workflow_id) {
          changed = true;
        }
        if ((workflowAction.contact_filter || data.contact_filter) &&
            (!_.isEqual(workflowAction.contact_filter, data.contact_filter))) {
          changed = true;
        }
        if (!_.isEqual(workflowAction.details, data.details)) {
          changed = true;
        }
        if (workflowAction.spread != data.spread) {
          changed = true;
        }
        if (workflowAction.delay != data.delay) {
          changed = true;
        }
        if (workflowAction.timeout != data.timeout) {
          changed = true;
        }
        if (workflowAction.limit != data.limit) {
          changed = true;
        }

        if (changed) {
          // Existing Workflow Action has been changed
          changes['update'].push(data);
        }

        // Check if Workflow Action connections have been changed
        var workflowConnection = _.find(workflowConnections, { "target_workflow_action_id": action.id });
        if ((workflowConnection && parent) && (
              (parent.id != workflowConnection.source_workflow_action_id) ||
              (0 != workflowConnection.slot)
            )) {
          changes['disconnect'].push({
            'id': data.id,
            'parent': workflowConnection.source_workflow_action_id,
            'slot': workflowConnection.slot,
          });
          changes['connect'].push({
            'id': data.id,
            'parent': parent.id,
            'slot': 0,
          });
        } else if (workflowConnection && !parent) {
          changes['disconnect'].push({
            'id': data.id,
            'parent': workflowConnection.source_workflow_action_id,
            'slot': workflowConnection.slot,
          });
        } else if ((!workflowConnection && parent) && (parent.id != 0)) {
          changes['connect'].push({
            'id': data.id,
            'parent': parent.id,
            'slot': 0,
          });
        }

      } else {

        // New Workflow Action

        changes['add'].push(data);

        if (parent) {
          changes['connect'].push({
            'id': data.id,
            'parent': parent.id,
            'slot': 0,
          });
        }
      }

      parent = action;

    });

    return changes;
  },

  generateId(actions) {
    // Generate a new (temporary) node ID

    let id = Math.floor(Math.random() * 10000) + 1;
    while (_.find(actions, { id }) !== undefined) {
      id = Math.floor(Math.random() * 10000) + 1;
    }

    return id;
  },
};
