let data = {
  allConditionTypes: [
    {
      'name': 'known',
      'label': 'is known',
      'value': null,
    },
    {
      'name': 'unknown',
      'label': 'is unknown',
      'value': null,
    },
    {
      'name': 'contains',
      'label': 'contains',
      'value': 'text',
    },
    {
      'name': 'not_contains',
      'label': "doesn't contain",
      'value': 'text',
    },
    {
      'name': 'equal',
      'label': "is equal",
      'value': 'number',
    },
    {
      'name': 'not_equal',
      'label': "is not equal",
      'value': 'number',
    },
    {
      'name': 'greater',
      'label': "is greater than",
      'value': 'number',
    },
    {
      'name': 'less',
      'label': "is less than",
      'value': 'number',
    },
    {
      'name': 'greater_or_equal',
      'label': "is greater or equal than",
      'value': 'number',
    },
    {
      'name': 'less_or_equal',
      'label': "is less or equal than",
      'value': 'number',
    },
    {
      'name': 'exact_datetime',
      'label': "is exactly at",
      'value': 'datetime',
    },
    {
      'name': 'not_exact_datetime',
      'label': "is not exactly at",
      'value': 'datetime',
    },
    {
      'name': 'before_datetime',
      'label': "is before",
      'value': 'datetime',
    },
    {
      'name': 'after_datetime',
      'label': "is after",
      'value': 'datetime',
    },
  ],
  conditionTypesByPropertyType: {
    'text': [
      'known',
      'unknown',
      'contains',
      'not_contains',
    ],
    'number': [
      'known',
      'unknown',
      'equal',
      'not_equal',
      'greater',
      'less',
      'greater_or_equal',
      'less_or_equal',
    ],
    'datetime': [
      'known',
      'unknown',
      'exact_datetime',
      'not_exact_datetime',
      'before_datetime',
      'after_datetime',
    ],
    'known': [
      'known',
      'unknown',
    ],
  },
  conditionByName: function (name) {
    return data.allConditionTypes.find(x => x.name === name);
  },
  conditionsByType: function (type) {
    var types = data.conditionTypesByPropertyType[type];
    types = data.allConditionTypes.filter(function (type) {
        return types.includes(type.name);
    });
    return types;
  },
};

let ContactPropertyFilters = function() {
  return data;
};

export default ContactPropertyFilters;
