export function displayDate(date, onlyRecent = false, future = false) {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  let seconds = Math.floor((new Date() - date) / 1000);
  if (seconds <= 0) {
    if (!future) {
      return 'just now';
    } else {
      seconds = -seconds;
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return 'in\xa0' + interval + "\xa0year" + ((interval > 1) ? 's' : '');
      }

      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return 'in\xa0' + interval + "\xa0month" + ((interval > 1) ? 's' : '');
      }

      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return 'in\xa0' + interval + "\xa0day" + ((interval > 1) ? 's' : '');
      }

      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return 'in\xa0' + interval + "\xa0hour" + ((interval > 1) ? 's' : '');
      }

      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return 'in\xa0' + interval + "\xa0minute" + ((interval > 1) ? 's' : '');
      }

      interval = Math.floor(seconds);
      return 'in\xa0' + interval + "\xa0second" + ((interval > 1) ? 's' : '');
    }
  }

  if (onlyRecent && Math.floor(seconds / 86400)) {
    // when onlyRecent is true, display relative time only for dates that
    // occured less than a day ago. Otherwise, return full date (without time).
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + "\xa0year" + ((interval > 1) ? 's\xa0ago' : '');
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + "\xa0month" + ((interval > 1) ? 's\xa0ago' : '');
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + "\xa0day" + ((interval > 1) ? 's\xa0ago' : '');
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + "\xa0hour" + ((interval > 1) ? 's\xa0ago' : '');
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + "\xa0minute" + ((interval > 1) ? 's\xa0ago' : '');
  }

  interval = Math.floor(seconds);
  return interval + "\xa0second" + ((interval > 1) ? 's\xa0ago' : '');
}
