<template>
  <div>
    <v-navigation-drawer
      v-if="this.$store.getters.authenticated"
      v-model="sidebar.display"
      app
    >
      <v-list
        v-if="false"
        color="blue"
        class="darken-3"
        dark
      >
        <v-list-item class="px-2 pt-3" link>
          <v-list-item-title class="title">
            <v-select
              v-model="$store.state.account.id"
              :items="$store.state.accounts"
              item-text="name"
              item-value="id"
              label="Account"
              @change="switchAccount()"
            ></v-select>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="px-2"
          link
        >
          <v-list-item-subtitle>{{ this.$store.state.user.name }} {{ this.$store.state.user.email }}</v-list-item-subtitle>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-item
          to="/"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="getAccountRoute('/workflows')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-cogs</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Workflows</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="getAccountRoute('/linkedin-conversations')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-forum</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Conversations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-if="account"
          prepend-icon="mdi-account-box-multiple"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Contacts</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            :to="getAccountRoute('/contacts')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Contacts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="getAccountRoute('/companies')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-briefcase</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Companies</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="getAccountRoute('/contact-lists')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-table-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Contact Lists</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="getAccountRoute('/contacts-import')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-cloud-upload</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Import from File</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          :to="getAccountRoute('/task-boards')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-bullseye-arrow</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tasks</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="getAccountRoute('/notes')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-note</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Notes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="getAccountRoute('/tags')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tags</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-if="account"
          prepend-icon="mdi-linkedin"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>LinkedIn</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :to="getAccountRoute('/linkedin')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Accounts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="getAccountRoute('/linkedin-conversations')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-forum</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Conversations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="getAccountRoute('/linkedin-searches')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-search</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Searches</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="getAccountRoute('/linkedin-connections')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Connections</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="account"
            class="pl-2"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Invitations</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              :to="getAccountRoute('/linkedin-invitations-received')"
              class="pl-6"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-account-multiple-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Received</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="getAccountRoute('/linkedin-invitations-sent')"
              class="pl-6"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-account-multiple-plus-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sent</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            :to="getAccountRoute('/linkedin-suggestions')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-question</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Suggestions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="getAccountRoute('/linkedin-views')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-target-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Views</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="getAccountRoute('/linkedin-groups')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Groups</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="account"
            class="pl-2"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Company Page</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :to="getAccountRoute('/linkedin-company-posts')"
              class="pl-6"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-note-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Posts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="getAccountRoute('/linkedin-company-invitations-sent')"
              class="pl-6"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-briefcase-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Invitations Sent</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-group
          v-if="account"
          prepend-icon="mdi-wrench"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Integrations</v-list-item-title>
              <base-info-icon
                v-if="false && $store.getters.isBasic"
                label="These features are available on Regular and Premium plans."
                to="/billing"
              />
            </v-list-item-content>
          </template>

          <v-list-item
            :to="getAccountRoute('/integrations')"
            class="pl-6"
            link
            :disabled="!$store.getters.isActive || $store.getters.isBasic"
          >
            <v-list-item-icon>
              <v-icon>mdi-api</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>API</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="getAccountRoute('/webhooks')"
            class="pl-6"
            link
            :disabled="!$store.getters.isActive || $store.getters.isBasic"
          >
            <v-list-item-icon>
              <v-icon>mdi-webhook</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Webhooks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-group
          v-if="account"
          prepend-icon="mdi-chart-box"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Reports</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            :to="getAccountRoute('/overview')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Overview</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="getAccountRoute('/reports')"
            class="pl-6"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Charts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
      >
        <v-list-item
          to="/accounts"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Accounts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/settings"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-tune</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider v-if="$store.getters.isOwner"></v-divider>

      <v-list
        v-if="$store.getters.isOwner"
        nav
      >

        <v-subheader>Account Management</v-subheader>

        <v-list-item
          :to="getAccountRoute('/account')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configuration</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="getAccountRoute('/users')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="getAccountRoute('/billing')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-credit-card-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manage Billing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

    </v-navigation-drawer>

    <v-app-bar
      color="blue"
      class="darken-3"
      dark
      elevation="0"
      app
      dense
      :absolute="!this.$store.getters.authenticated"
      :hide-on-scroll="!this.$store.getters.authenticated"
    >
      <v-app-bar-nav-icon
        v-if="this.$store.getters.authenticated"
        @click="sidebar.display = !sidebar.display"
      ></v-app-bar-nav-icon>

      <v-toolbar-title v-if="!smallMobileScreen420"></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip
          v-if="$route.name !== 'Global Search' && $store.getters.authenticated"
          bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            :to="getAccountRoute('/search')"
            link
            icon
            class="mx-1"
            small
            v-on="on"
          >
            <v-icon small disabled>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>Global Search</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            href="https://docs.webwalker.io/wiki/"
            target="_blank"
            link
            icon
            class="mx-1"
            small
            v-on="on"
          >
            <v-icon small>mdi-book-open-outline</v-icon>
          </v-btn>
        </template>
        <span>Knowledge Base</span>
      </v-tooltip>

      <main-nav-notifications
        v-if="$store.getters.authenticated"
      ></main-nav-notifications>

      <v-btn
        v-if="$store.getters.authenticated"
        to="/settings"
        link
        icon
        class="mx-1"
        small
      >
        <v-icon small>mdi-account-circle</v-icon>
      </v-btn>

      <div
        v-if="$store.getters.authenticated"
      >
        <v-tooltip v-if="$darkMode" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', false)">
              <v-icon small color="yellow" class="mr-1">mdi-moon-waxing-crescent</v-icon>
            </v-btn>
          </template>
          <span>Disable dark mode</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', true)">
              <v-icon small color="yellow">mdi-white-balance-sunny</v-icon>
            </v-btn>
          </template>
          <span>Enable dark mode</span>
        </v-tooltip>
      </div>

      <v-divider
        v-if="$store.getters.authenticated"
        v-show="!this.isAuthView"
        vertical
        class="ml-2 mr-0 mr-sm-4"
      ></v-divider>

      <v-menu
        v-if="$store.getters.authenticated"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="main-nav-btn mx-1"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-0 mr-sm-3" small>mdi-domain</v-icon>
            <span v-if="!smallMobileScreen420">{{ ((account) ? account.name : 'Select Account') }}</span>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-subheader style="max-height: 30px;">{{ this.$store.state.user.name }} {{ this.$store.state.user.email }}</v-subheader>
          <v-subheader
            v-if="account"
          >{{ account.name }}</v-subheader>

          <v-divider></v-divider>

          <v-subheader>Account Management</v-subheader>

          <v-list-item
            :to="getAccountRoute('/account')"
            class="pl-6"
            link
            dense
          >
            <v-list-item-content>
              <v-list-item-title>Configuration</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-cog</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            v-if="$store.getters.isOwner"
            :to="getAccountRoute('/users')"
            link
            dense
          >
            <v-list-item-content>
              <v-list-item-title>Manage Users</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-account-group</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            v-if="$store.getters.isOwner"
            :to="getAccountRoute('/billing')"
            link
            dense
          >
            <v-list-item-content>
              <v-list-item-title>Manage Billing</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-credit-card-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            to="/accounts"
            link
          >
            <v-list-item-content>
              <v-list-item-title>My Accounts</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            v-for="(acc, index) in this.$store.state.accounts"
            :key="index"
            :disabled="acc.id == account.id"
            @click="switchAccountRoute(acc)"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ acc.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-location-enter</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider
        v-if="$store.getters.authenticated"
        v-show="!this.isAuthView"
        vertical
        class="ml-0 ml-sm-2 mr-0 mr-sm-4"
      ></v-divider>

      <v-btn
        class="main-nav-btn mx-2"
        small
        text v-if="!this.$store.getters.authenticated"
        :to="{name: 'Login'}"
      >
        <v-icon class="mr-1" small>mdi-login</v-icon>
        Login
      </v-btn>
      <v-btn
        class="main-nav-btn mx-2"
        small
        text v-if="!this.$store.getters.authenticated"
        :to="{name: 'Sign Up'}"
      >
        Sign Up
      </v-btn>
      <v-btn
        class="mx-2"
        icon
        small
        v-if="this.$store.getters.authenticated"
        @click="logoutConfirmation = true"
      >
        <v-icon small>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <base-dialog-confirmation
      v-model="logoutConfirmation"
      prompt="Do you really want to logout?"
      confirm-text="Logout"
      confirm-color="red"
      @confirm="logout"
      @cancel="logoutConfirmation = false"
    ></base-dialog-confirmation>
  </div>
</template>

<script>
import MainNavNotifications from '@/components/layout/MainNavNotifications.vue';

export default {
  name: 'MainNavSidebar',

  components: {
    MainNavNotifications,
  },

  data: () => ({
    logoutConfirmation: false,
    sidebar: {
      display: false,
    },
  }),

  computed: {
    accountId: function () {
      var params = this.$router.history.current.params;
      if (params && params['accountId']) return params['accountId'];
      if (this.$store.state.account) return this.$store.state.account.id;
      if (this.$store.state.accounts) return this.$store.state.accounts[0];
      return null;
    },
    account: function () {
      if (!this.$store.state.accounts || !this.accountId) return false;
      return this.$store.state.accounts.find(x => x.id == this.accountId);
    },
    isAuthView() {
      // Check if on auth pages (login, sign up, password reset, etc)
      if (this.$route && this.$route.meta) {
        return this.$route.meta.public;
      }
      return false;
    },
    smallMobileScreen420() {
      return window.screen.width < 420;
    },
  },

  methods: {
    getAccountRoute: function (route) {
      if (!this.accountId) return route;
      return '/account/' + this.accountId + route;
    },
    switchAccount: function () {
      this.$store.commit('setAccount', this.$store.state.accounts.find(x => x.id == this.accountId));
      window.location.reload();
    },
    logout: function () {
      this.$emit('logout');
    },
  },
};
</script>
