import Api from "@/api/Api";

export default {
  getContactProperties(accountId, page, pageSize, sortBy, sortDesc, query) {
    var params = {};
    if (query) params['query'] = query;
    if (page) params['page'] = page;
    if (pageSize) params['paginate'] = pageSize;
    if (sortBy && sortBy.length) params['sort_by'] = sortBy[0];
    if (sortDesc && sortDesc.length) params['sort_order'] = ((sortDesc[0]) ? 'desc' : 'asc');
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get("/accounts/" + accountId + '/contact-properties' + params);
  },

  addContactProperty(accountId, data) {
    return Api().post("/accounts/" + accountId + '/contact-properties', data);
  },

  getContactProperty(contactPropertyId) {
    return Api().get('/contact-properties/' + contactPropertyId);
  },

  updateContactProperty(contactPropertyId, data) {
    return Api().patch('/contact-properties/' + contactPropertyId, data);
  },

  removeContactProperty(contactPropertyId) {
    return Api().delete('/contact-properties/' + contactPropertyId);
  },
};
