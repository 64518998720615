<template>
  <v-app
    id="webwalker"
    :class="{ 'is-auth': isAuthView }"
  >
    <v-sheet
      v-show="!this.loaded"
      class="pa-3"
      :color="(isAuthView) ? 'blue darken-3' : (this.isDarkMode) ? 'grey darken-4' : 'white'"
      style="z-index: 100; width: 100%; height: 100%; position: absolute; transition: opacity cubic-bezier(0, 0.52, 1, 1) 0.25s;"
      :style="{ opacity: (this.loading) ? 1 : 0 }"
    >
      <div
        class="pb-6"
        style="display: flex; align-items: center; justify-content: center; height: 100%"
      >
        <v-progress-circular
          :size="70"
          :width="3"
          :color="(isAuthView) ? 'white' : 'blue darken-3'"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-sheet>
    <main-nav
      v-if="this.isTopLayout && !$store.state.isMaintenance && !isSidebarLayout"
      @logout="logout()"
    ></main-nav>

    <v-progress-linear
      v-if="$store.state.isLoadingRoute"
      indeterminate
      :color="(isAuthView) ? 'white' : 'blue darken-3'"
      fixed
      style="bottom: 0;"
    ></v-progress-linear>

    <v-btn
      v-if="$store.getters.isTrial && !$route.matched.some(({ name }) => name === 'Billing')"
      id="trial-button"
      class="mx-auto darken-3 px-1 px-sm-4"
      to="/billing"
      color="orange"
      dark
      fixed
      bottom
      left
      style="z-index: 999;"
      :style="$vuetify.breakpoint.xsOnly ? 'height: 26px;' : ''"
    >
      <span
        v-if="$store.getters.trialDaysLeft"
      >
        Trial Ends in <strong>{{ $store.getters.trialDaysLeft }}</strong> Days
      </span>
      <span
        v-else
      >Trial Expired</span>
    </v-btn>

    <main-nav-sidebar
      v-if="this.isSidebarLayout"
      @logout="logout()"
    ></main-nav-sidebar>

    <v-main
      class="pt-12"
      :class="{ 'background': !isAuthView, 'blue darken-3': isAuthView }"
    >

      <!-- Provides the application the proper gutter -->
      <!-- <v-container fluid> -->

        <!-- If using vue-router -->
        <transition-container>
          <router-view></router-view>
        </transition-container>
      <!-- </v-container> -->
    </v-main>
  </v-app>
</template>

<script>
import AppApi from "@/api/AppApi";
import AuthApi from "@/api/AuthApi";
import MainNav from '@/components/layout/MainNav.vue';
import MainNavSidebar from '@/components/layout/MainNavSidebar.vue';
import TransitionContainer from '@/components/layout/TransitionContainer.vue';

export default {
  name: 'Webwalker',

  components: {
    MainNav,
    MainNavSidebar,
    TransitionContainer,
  },

  data: () => ({
    layout: 'top',
    interval: null,
    refreshInterval: 60,
    loading: true,
    loaded: false,
  }),

  computed: {
    isSidebarLayout: function () {
      return (this.layout == 'sidebar' || this.$vuetify.breakpoint.smAndDown);
    },

    isTopLayout: function () {
      return (this.layout == 'top');
    },

    isDarkMode() {
      return this.$store.state.isDarkMode;
    },

    isAuthView() {
      // Check if on auth pages (login, sign up, password reset, etc)
      if (this.$route && this.$route.meta) {
        return this.$route.meta.public;
      }

      return false;
    },
  },

  watch: {
    isDarkMode() {
      this.$darkMode = this.$store.state.isDarkMode;
      this.rootScrollColor();
    },

    $route (/*to, from*/) {
      // Clear the list of erros when user goes to another page
      this.$store.dispatch('clearErrors');
    },
  },

  mounted() {

    AppApi.getVersion()
    .then((response) => {
      let version = response.data.version;
      let localVersion = localStorage.getItem('verson');
      if (localVersion != version) {
        localStorage.setItem('verson', version);
        if (localVersion && version) {
          // Refresh the page (attempt to clear cache).
          setTimeout(() => {
            (async function (url) {
                await fetch(url, {
                    headers: {
                        Pragma: 'no-cache',
                        Expires: '-1',
                        'Cache-Control': 'no-cache',
                    },
                });
                window.location.href = url;
                window.location.reload();
            })(window.location.href);
          }, 1);
        }
      }
    })
    .catch(error => {
      if (error.response.data.errors) {
        this.$store.dispatch('pushErrors', error.response.data.errors);
      }
    });

    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        this.loaded = true;
      }, 250);
    }, 500);

    // Refresh the user data periodically
    this.loadData();
    this.interval = setInterval(() => {
        this.loadData();
    }, this.refreshInterval * 1000);
    this.rootScrollColor();
  },

  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },

  methods: {

    loadData: function () {
      // Load user data from backend
      this.$store.dispatch('loadUser');

      // Load dark mode option from the user settings
      this.$darkMode = this.$store.state.isDarkMode;
    },

    logout: function () {
      // Clear tokens on the backend
      AuthApi.logout()
      .then(() => {
        // Reset chatwoot session
        if (process.env.VUE_APP_CHATWOOT_TOKEN &&
            window.$chatwoot) {
          window.$chatwoot.reset();
        }

        // Clear local storage
        localStorage.clear();

        // Reload the tab
        window.location.reload();
      })
      .catch(error => {
        if (error.response.data.errors) {
          this.$store.dispatch('pushErrors', error.response.data.errors);
        }
        localStorage.clear();
      });
    },

    rootScrollColor: function () {
      let root = document.querySelector(':root');
      if (this.isDarkMode) {
        root.classList.add('scroll-dark');
      } else {
        root.classList.remove('scroll-dark');
      }
    },

  },
}
</script>
