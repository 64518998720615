let data = {
  allConditionTypes: [
    {
      'name': 'connection',
      'label': 'connection',
      'value': null,
    },
    {
      'name': 'not_connection',
      'label': 'not connection',
      'value': null,
    },
    {
      'name': 'replied',
      'label': 'replied to conversation',
      'value': null,
    },
    {
      'name': 'not_replied',
      'label': 'not replied to conversation',
      'value': null,
    },
    {
      'name': 'invitation_received',
      'label': 'invitation to connect (received)',
      'value': null,
    },
    {
      'name': 'not_invitation_received',
      'label': 'not invitation to connect (received)',
      'value': null,
    },
    {
      'name': 'invitation_sent',
      'label': 'invitation to connect (sent)',
      'value': null,
    },
    {
      'name': 'not_invitation_sent',
      'label': 'not invitation to connect (sent)',
      'value': null,
    },
    {
      'name': 'suggestion',
      'label': 'suggested connection',
      'value': null,
    },
    {
      'name': 'not_suggestion',
      'label': 'not suggested connection',
      'value': null,
    },
    {
      'name': 'view',
      'label': 'profile view',
      'value': null,
    },
    {
      'name': 'not_view',
      'label': 'not profile view',
      'value': null,
    },
  ],
  conditionByName: function (name) {
    return data.allConditionTypes.find(x => x.name === name);
  },
  conditions: function () {
    return data.allConditionTypes;
  },
};

let ContactLinkedinFilters = function() {
  return data;
};

export default ContactLinkedinFilters;
