import Api from "@/api/Api";

export default {
  updateUser(data) {
    return Api().patch("/user", data);
  },
  sendVerifyingEmail(data) {
    return Api().post("/send-verifying-email", data);
  },
};
