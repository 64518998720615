<template>
  <div
    class="ma-0 pa-0"
    :data-node-id="this.node.id"
  >
    <v-card
      v-if="((this.node.data.type != 'delay') && this.node.data.delay)"
      class="px-3 py-1 text-center background"
      elevation="0"
      height="32"
      style="position: absolute; top: -48px; left: 87px;"
    >
      <v-card-text
        class="py-0"
      >
        <div>
          Wait
          {{ this.node.data.delay }}
          {{ (this.node.data.delay == 1) ? 'hour' : 'hours' }}
        </div>
      </v-card-text>
    </v-card>
    <v-badge
      :content="this.node.data.total_contacts"
      :value="this.node.data.total_contacts > 0"
      color="orange"
      overlap
    >
      <v-card
        class="flowy-drag-handle"
        :color="node.color"
        :elevation="1"
        :class="{ 'removed': this.node.removed, 'detached': this.node.detached }"
        @dblclick="editNode"
        width="300"
        min-height="50"
      >
        <v-app-bar
          flat
          dense
          dark
          color="light-blue darken-4"
        >

          <v-menu
            v-if="this.node.data.type != 'start'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              ><v-icon small>mdi-menu</v-icon></v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="!this.node.removed"
                @click="editNode()"
              >
                <v-list-item-content>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="!this.node.removed"
                @click="remove()"
              >
                <v-list-item-content>
                  <v-list-item-title>Remove</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="this.node.removed"
                @click="remove()"
              >
                <v-list-item-content>
                  <v-list-item-title>Restore</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-icon
            v-if="this.node.data.type == 'start'"
            small
          >mdi-rocket-launch</v-icon>
          <v-icon
            v-else-if="getNodeIcon(this.node.data.type)"
            small
          >{{ getNodeIcon(this.node.data.type) }}</v-icon>

          <v-toolbar-title
            class="text-subtitle-1 pl-3"
          >
            {{ title }}
          </v-toolbar-title>

          <base-warning-icon
            v-if="isListMissing(this.node)"
            label="Please, select a list."
          />
          <base-warning-icon
            v-if="isTagMissing(this.node)"
            label="Please, select a tag."
          />
          <base-warning-icon
            v-if="isNoteMissing(this.node)"
            label="Please, select a note."
          />
          <base-warning-icon
            v-if="isTaskMissing(this.node)"
            label="Please, select a task."
          />
          <base-warning-icon
            v-if="isWorkflowMissing(this.node)"
            label="Please, select a workflow."
          />
          <base-warning-icon
            v-if="isLinkedinMissing(this.node)"
            label="Please, select a LinkedIn account."
          />

          <v-spacer></v-spacer>


          <v-tooltip
            v-if="this.node.new"
            bottom
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
              >mdi-plus</v-icon>
            </template>
            <span>This action is new and will be created when the workflow is saved</span>
          </v-tooltip>

          <v-tooltip
            v-if="this.node.removed"
            bottom
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
              >mdi-minus</v-icon>
            </template>
            <span>This action has been removed and will be deleted when the workflow is saved</span>
          </v-tooltip>

          <v-tooltip
            v-if="this.node.detached"
            bottom
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
              >mdi-connection</v-icon>
            </template>
            <span>This action is detached or attached to a removed action and will be deleted when the workflow is saved</span>
          </v-tooltip>

          <v-tooltip
            v-if="this.node.changed"
            bottom
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
              >mdi-pencil</v-icon>
            </template>
            <span>This action has been edited and will be updated when the workflow is saved</span>
          </v-tooltip>

          <v-tooltip
            v-if="this.node.moved"
            bottom
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
              >mdi-cursor-move</v-icon>
            </template>
            <span>This action has been moved and will be updated when the workflow is saved</span>
          </v-tooltip>

          <v-tooltip
            bottom
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >mdi-drag</v-icon>
            </template>
            <span>Drag this action to change its position</span>
          </v-tooltip>
        </v-app-bar>

        <v-card-text>
          <div v-html="description"></div>
          <div
            v-if="this.node.data.timeout"
            class="mt-3"
          >
            Timeout: <b>{{ this.node.data.timeout }}</b> hours.
          </div>
          <div
            v-if="this.node.data.details && this.node.data.details.message"
            class="mt-3"
          >
            <v-alert
              dark
              dense
              outlined
              color="blue-grey"
              style="white-space: pre-line; overflow: hidden;"
            >
              {{ (this.node.data.details.message.length > 100) ? this.node.data.details.message.substring(0,97) + "..." : this.node.data.details.message }}
            </v-alert>
          </div>
          <div
            v-if="this.node.data.linkedin"
            class="mt-3"
          >
            As
            <base-linkedin-account
              v-model="this.node.data.linkedin"
              @click="editNode()"
            ></base-linkedin-account>
          </div>
        </v-card-text>
      </v-card>
    </v-badge>
    <v-btn
      v-if="(this.node.hasChildren && this.node.hasSlots)"
      elevation="1"
      fab
      absolute
      right
      bottom
      x-small
      class="mt-7"
      style="margin-right: 118px; margin-bottom: -32px"
      @click="newNode"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
          >mdi-plus</v-icon>
        </template>
        <span>Add Action</span>
      </v-tooltip>
    </v-btn>
	</div>
</template>

<script>
import Workflow from "@/helpers/Workflow";

export default {
  name: 'WorkflowActionNode',

  props: {
    remove: {
      type: Function,
      required: true,
    },
    node: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },

  data () {
    return {

    };
  },

  mounted () {

  },

  destroyed () {

  },

  methods: {

    newNode () {
      this.node.addNew = true;
      this.remove();
    },

    editNode () {
      if (this.node.data.type == 'start') return;
      this.node.edit = true;
      this.remove();
    },

    isParameterMissing (node, type, value) {
      if ('parameter' in node.data) return false;
      if (node.data.type.startsWith(type + '_') &&
          !value) {
        return true;
      }
      return false;
    },

    isListMissing (node) {
      return this.isParameterMissing(node, 'list', node.data.contact_list);
    },

    isTagMissing (node) {
      return this.isParameterMissing(node, 'tag', node.data.tag);
    },

    isNoteMissing (node) {
      return this.isParameterMissing(node, 'note', node.data.note);
    },

    isTaskMissing (node) {
      return this.isParameterMissing(node, 'task', node.data.task);
    },

    isWorkflowMissing (node) {
      return this.isParameterMissing(node, 'workflow', node.data.workflow);
    },

    isLinkedinMissing (node) {
      return this.isParameterMissing(node, 'linkedin', node.data.linkedin);
    },

    getNodeIcon: function (type) {
      return Workflow.getTypeIcon(type);
    },

  },
};
</script>

<style scoped>
.v-card.removed > :not(.v-card__progress) {
  opacity: 0.6;
}
.v-card.detached > :not(.v-card__progress) {
  opacity: 0.6;
}
</style>
