let data = {
  allConditionTypes: [
    {
      'name': 'included',
      'label': 'included',
      'value': null,
    },
    {
      'name': 'not_included',
      'label': 'not included',
      'value': null,
    },
  ],
  conditionByName: function (name) {
    return data.allConditionTypes.find(x => x.name === name);
  },
  conditions: function () {
    return data.allConditionTypes;
  },
};

let ContactTaskFilters = function() {
  return data;
};

export default ContactTaskFilters;
