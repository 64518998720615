let data = {
  allConditionTypes: [
    {
      'name': 'member',
      'label': 'member',
      'value': null,
    },
    {
      'name': 'not_member',
      'label': 'not member',
      'value': null,
    },
  ],
  conditionByName: function (name) {
    return data.allConditionTypes.find(x => x.name === name);
  },
  conditions: function () {
    return data.allConditionTypes;
  },
};

let ContactListFilters = function() {
  return data;
};

export default ContactListFilters;
