let data = {};

let ContactCompanies = function(accountId) {
  if (!accountId) return {};

  if (!data[accountId]) {
    data[accountId] = {
      default: [
        {
          'name': 'position',
          'label': 'Contact position',
          'type': 'boolean',
        },
        {
          'name': 'title',
          'label': 'Position title',
          'type': 'text',
        },
        {
          'name': 'company',
          'label': 'Company name',
          'type': 'text',
        },
        {
          'name': 'present_company',
          'label': 'Present company',
          'type': 'boolean',
        },
        {
          'name': 'present_title',
          'label': 'Present title',
          'type': 'text',
        },
        {
          'name': 'present_company_name',
          'label': 'Present company name',
          'type': 'text',
        },
      ],
      properties: function () {
        return  data[accountId].default;
      },
      property: function (property) {
        let properties = data[accountId];
        return properties.getProperty(property);
      },
      getProperty: function (property) {
        let properties = data[accountId].default;
        return properties.find(x => x.name === property);
      },
    };
  }

  return data[accountId];
};

export default ContactCompanies;
