import ContactPropertiesApi from "@/api/ContactPropertiesApi";

let data = {};

let ContactProperties = function(accountId) {
  if (!accountId) return {};

  if (!data[accountId]) {
    data[accountId] = {
      default: [
        {
          'name': 'first_name',
          'label': 'First name',
          'type': 'text',
        },
        {
          'name': 'last_name',
          'label': 'Last name',
          'type': 'text',
        },
        {
          'name': 'email',
          'label': 'Email',
          'type': 'text',
        },
        {
          'name': 'linkedin',
          'label': 'LinkedIn',
          'type': 'known',
        },
        {
          'name': 'phone',
          'label': 'Phone',
          'type': 'text',
        },
        {
          'name': 'industry',
          'label': 'Industry',
          'type': 'text',
        },
        {
          'name': 'city',
          'label': 'City',
          'type': 'text',
        },
        {
          'name': 'state',
          'label': 'State',
          'type': 'text',
        },
        {
          'name': 'country',
          'label': 'Country',
          'type': 'text',
        },
      ],
      custom: [],
      loaded: false,
      loading: false,
      defaultProperties: function () {
        let properties = data[accountId];
        return properties.default;
      },
      properties: async function () {
        let properties = data[accountId];
        if (!properties.loaded) {
          properties.loading = true;
          await ContactPropertiesApi.getContactProperties(accountId, 1, 999).then(response => {
            properties.custom = response.data.data;
            properties.loading = false;
            properties.loaded = true;
          });
        }
        return properties.default.concat(properties.custom);
      },
      property: async function (property) {
        let properties = data[accountId];
        if (!properties.loaded) {
          await properties.properties();
        }
        return properties.getProperty(property);
      },
      getProperty: function (property) {
        let properties = data[accountId];
        return (properties.default.concat(properties.custom)).find(x => x.name === property);
      },
    };
  }

  return data[accountId];
};

export default ContactProperties;
