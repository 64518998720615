import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from '@/plugins/vuetify';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
import AuthApi from "@/api/AuthApi";
import SettingsApi from "@/api/SettingsApi";

// Use Secure (encrypted) Local Storage for storing data in the browser
const ls = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: process.env.VUE_APP_LS_ENCRYPTION_SECRET,
});

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "webwalker",
      storage: {
        getItem: (key) => {
          try {
            return ls.get(key)
          }
          catch (err) {
            ls.clear()
            return ls.get(key)
          }
        },
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    })
  ],
  state: {
    token: false, // Core API token
    user: false, // Current user details
    accounts: false, // List of accounts
    account: false, // Current account; this probably should be moved to getters (should be URL based)
    isDarkMode: false, // Dark\Light theme
    reverseMessageOrder: false, // Reverse message order in conversations
    conversationFilterType: '', // The last selected type filter in conversations
    conversationFilterReply: false, // The last selected reply-only filter in conversations
    expandTaskCards: false, // Expand task cards in task boards
    isMaintenance: false, // Maintenance mode
    isLoadingRoute: false, // Route loading
    errors: [], // List of errors
  },
  getters: {
    authenticated(state) {
      // Consider authenticated if has token
      if (state.token) {
        return true;
      }
      return false;
    },
    subscription(state) {
      // Get subscription for the current account
      if (state.account &&
          state.account.subscription) {
        return state.account.subscription;
      }
      return null;
    },
    isOwner(state) {
      // Is the owner of the current account?
      if (state.account &&
          state.account.owner &&
          state.user) {
        return (state.account.owner.id == state.user.id);
      }
      return false;
    },
    isActive(state, getters) {
      // Is the current account active?
      if (getters.subscription) {
        return getters.subscription.active;
      }
      return false;
    },
    isTrial(state, getters) {
      // Is the current account on free trial?
      if (getters.subscription) {
        return getters.subscription.trial;
      }
      return false;
    },
    trialDaysLeft(state, getters) {
      // How many days left in the free trial?
      if (getters.subscription &&
          getters.subscription.trial) {
        var days = Math.ceil(((new Date(getters.subscription.expires_at)).getTime() - (new Date()).getTime())/(24*60*60*1000));
        if (days <= 0) {
          days = 0;
        }
        return days;
      }
      return null;
    },
    isBasic(state, getters) {
      // Is the current account on Basic plan?
      if (getters.subscription) {
        var planType = getters.subscription.plan_type;
        if (planType != 'basic') {
          return false;
        }
      }
      return true; // default to yes since it's the default plan
    },
    isRegular(state, getters) {
      // Is the current account on Regular plan?
      if (getters.subscription) {
        var planType = getters.subscription.plan_type;
        if (planType == 'regular') {
          return true;
        }
      }
      return false;
    },
    isPremium(state, getters) {
      // Is the current account on Premium plan?
      if (getters.subscription) {
        var planType = getters.subscription.plan_type;
        if (planType == 'premium') {
          return true;
        }
      }
      return false;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setAccounts(state, accounts) {
      state.accounts = accounts;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setDarkMode(state, isDarkMode) {
      state.isDarkMode = isDarkMode;
    },
    setReverseMessageOrder(state, reverseMessageOrder) {
      state.reverseMessageOrder = reverseMessageOrder;
    },
    setConversationFilterType(state, conversationFilterType) {
      state.conversationFilterType = conversationFilterType;
    },
    setConversationFilterReply(state, conversationFilterReply) {
      state.conversationFilterReply = conversationFilterReply;
    },
    setExpandTaskCards(state, expandTaskCards) {
      state.expandTaskCards = expandTaskCards;
    },
    setLoadingRoute(state, isLoadingRoute) {
      state.isLoadingRoute = isLoadingRoute;
    },
    setMaintenance(state, isMaintenance) {
      state.isMaintenance = isMaintenance;
    },
    pushErrors(state, errors) {
      state.errors.push(errors);
      if ((state.errors.length > 3) || (
        Vuetify.framework.breakpoint.smAndDown
        &&
        (state.errors.length > 1)
      )) {
        state.errors.shift();
      }
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  actions: {
    loadUser({ commit, state }) {
      AuthApi.auth().then(response => {
        // Load user details
        commit('setUser', response.data.data);

        // Load accounts
        if (response.data.data.accounts) {
          commit('setAccounts', response.data.data.accounts);

          // Load the current account
          if (response.data.data.accounts.length) {
            response.data.data.accounts.forEach((account) => {
              if (!state.account || (state.account.id == account.id)) {
                commit('setAccount', account);
              }
            });
          } else {
            commit('setAccount', false);
          }
        }

        // Load settings
        if (response.data.data.settings) {
          commit('setDarkMode', response.data.data.settings.dark_mode);
          commit('setReverseMessageOrder', response.data.data.settings.reverse_message_order || false);
          commit('setConversationFilterType', response.data.data.settings.conversation_filter_type || false);
          commit('setExpandTaskCards', response.data.data.settings.expand_task_cards || false);
        }

        // Initialize Chatwoot
        if (process.env.VUE_APP_CHATWOOT_TOKEN &&
            window.$chatwoot) {
          // Prepare the contact attributes to push them to the chatwoot
          var attributes = {
            user_id: response.data.data.id,
            user_created_at: response.data.data.created_at,
          };

          // Include account & plan data when available
          if (state.account) {
            attributes['account_id'] = state.account.id;
            attributes['account_name'] = state.account.name;
            attributes['account_created_at'] = state.account.created_at;
            if (state.account.subscription) {
              attributes['account_plan_type'] = state.account.subscription.plan_type;
              attributes['account_plan_instances'] = state.account.subscription.units;
              attributes['account_plan_trial'] = state.account.subscription.trial;
              attributes['account_plan_expires_at'] = state.account.subscription.expires_at;
            }
          }

          window.$chatwoot.setCustomAttributes(attributes);

          // Setup user data in the chatwoot
          window.$chatwoot.setUser("webwalker-" + response.data.data.id, {
            email: response.data.data.email,
            name: response.data.data.name,
            identifier_hash: response.data.data.chatwoot_identifier,
          });
        }

        // Disable maintenance mode if enabled
        if (state.isMaintenance) {
          commit('setMaintenance', false);
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          // commit('pushErrors', error.response.data.errors); // Uncomment to push "Unauthenticated." errors.
        }
        if (error.response.status == 503) {
          // Switch into maintenance mode
          if (!state.isMaintenance) {
            commit('setMaintenance', true);
            window.location.reload();
          }
        } else {
          localStorage.clear();
        }
      });
    },
    switchDarkMode({ commit }, isDarkMode) {
      // Store the last choice (dark mode on\off) in the user settings
      SettingsApi.updateUser({
        settings: {'dark_mode': isDarkMode},
      });

      // Switch the dark mode on\off
      commit('setDarkMode', isDarkMode);
    },
    switchReverseMessageOrder({ commit }, reverseMessageOrder) {
      // Store the last choice (reverse message order on\off) in the user settings
      SettingsApi.updateUser({
        settings: {'reverse_message_order': reverseMessageOrder},
      });

      // Switch the reverse message order on\off
      commit('setReverseMessageOrder', reverseMessageOrder);
    },
    updateConversationFilterType({ commit }, conversationFilterType) {
      // Store the last choice in the user settings
      SettingsApi.updateUser({
        settings: {'conversation_filter_type': conversationFilterType},
      });

      // Update the filter value
      commit('setConversationFilterType', conversationFilterType);
    },
    updateConversationFilterReply({ commit }, conversationFilterReply) {
      // Store the last choice in the user settings
      SettingsApi.updateUser({
        settings: {'conversation_filter_reply': conversationFilterReply},
      });

      // Update the filter value
      commit('setConversationFilterReply', conversationFilterReply);
    },
    switchExpandTaskCards({ commit }, expandTaskCards) {
      // Store the last choice (expand task cards on\off) in the user settings
      SettingsApi.updateUser({
        settings: {'expand_task_cards': expandTaskCards},
      });
      // Switch the expand task cards on\off
      commit('setExpandTaskCards', expandTaskCards);
    },
    loadingRoute({ commit }, isLoadingRoute) {
      // Switch the route loading on\off
      commit('setLoadingRoute', isLoadingRoute);
    },
    pushErrors({ commit }, errors) {
      // Add an error to the list of errors
      commit('pushErrors', errors);
    },
    clearErrors({ commit, state }) {
      // Clear the list of errors
      if (state.errors.length) {
        commit('clearErrors');
      }
    },
  },
});

export default store;
