import ContactProperties from "@/helpers/ContactProperties";
import ContactPropertyFilters from "@/helpers/ContactPropertyFilters";
import ContactCompanies from "@/helpers/ContactCompanies";
import ContactCompanyFilters from "@/helpers/ContactCompanyFilters";
import ContactListFilters from "@/helpers/ContactListFilters";
import ContactTagFilters from "@/helpers/ContactTagFilters";
import ContactNoteFilters from "@/helpers/ContactNoteFilters";
import ContactTaskFilters from "@/helpers/ContactTaskFilters";
import ContactUserFilters from "@/helpers/ContactUserFilters";
import ContactWorkflowFilters from "@/helpers/ContactWorkflowFilters";
import ContactLinkedinFilters from "@/helpers/ContactLinkedinFilters";

let data = {
  loadProperties: function (accountId) {
    return ContactProperties(accountId).properties();
  },
  property: function (accountId, property) {
    return ContactProperties(accountId).property(property);
  },
  getProperty: function (accountId, property) {
    return ContactProperties(accountId).getProperty(property);
  },
  company: function (accountId, property) {
    return ContactCompanies(accountId).property(property);
  },
  getCompany: function (accountId, property) {
    return ContactCompanies(accountId).getProperty(property);
  },
  getCondition: function (type, condition) {
    if (type == 'property') {
      return ContactPropertyFilters().conditionByName(condition);
    } else if (type == 'company') {
      return ContactCompanyFilters().conditionByName(condition);
    } else if (type == 'list') {
      return ContactListFilters().conditionByName(condition);
    } else if (type == 'tag') {
      return ContactTagFilters().conditionByName(condition);
    } else if (type == 'note') {
      return ContactNoteFilters().conditionByName(condition);
    } else if (type == 'task') {
      return ContactTaskFilters().conditionByName(condition);
    } else if (type == 'workflow' || type == 'workflowTemplate') {
      return ContactWorkflowFilters().conditionByName(condition);
    } else if (type == 'linkedin') {
      return ContactLinkedinFilters().conditionByName(condition);
    } else if (type == 'user') {
      return ContactUserFilters().conditionByName(condition);
    }
    return null;
  },
  getSimpleDescription: function (accountId, workflowId, filter, isHtml) {
    if (!filter ||
        !filter.condition ||
        !filter.condition.group ||
        !filter.condition.group.length) {
      return 'true';
    }
    var hasSubGroups = false;
    var description = '';

    filter.condition.group.forEach(filterCondition => {
      if (filterCondition.group && filterCondition.group.length) {
        hasSubGroups = true;
      } else {
        if (description) {
          description += ' ' + filter.condition.type + ' ';
        }

        if (filterCondition.property) {
          var property = this.getProperty(accountId, filterCondition.property);
          if (property) {
            if (isHtml) description += '<b>';
            description += property.label.toLowerCase();
            if (isHtml) description += '</b>';
          }
        }

        if (filterCondition.list) {
          description += 'list';
        }

        if (filterCondition.tag) {
          description += 'tag';
        }

        if (filterCondition.note) {
          description += 'note';
        }

        if (filterCondition.task) {
          description += 'task';
        }

        if (filterCondition.workflow) {
          if (filterCondition.workflow != workflowId) {
            description += 'workflow';
          }
        }

        if (filterCondition.linkedin) {
          description += 'linkedIn';
        }

        if (filterCondition.user) {
          description += 'user';
        }

        if (filterCondition.condition) {
          var condition = this.getCondition(filterCondition.type, filterCondition.condition);
          if (condition) {
            description += ' ' + condition.label;
          }
        }

        if (filterCondition.value) {
          if (isHtml) description += '<b>';
          description += ' "' + filterCondition.value + '"';
          if (isHtml) description += '</b>';
        }
      }
    });

    if (hasSubGroups || !description) {
      return 'complex condition';
    }

    return description;
  },
};



let ContactFilters = function() {
  return data;
};

export default ContactFilters;
